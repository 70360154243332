import { Outlet, NavLink, Link } from "react-router-dom";

import github from "../../assets/github.svg";

import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer} style={{flex: 1}}>
                        <h3 className={styles.headerTitle}>
                            <span><img src={"/logo_lameda_vector.svg"} style={{height: "20px"}}/> Lameda</span> | Demo
                        </h3>
                    </Link>
                    <nav style={{flex: 1}}>
                        <ul className={styles.headerNavList} style={{justifyContent: "center"}}>
                            <li>
                                <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Chat
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/qa" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Q&A
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/email" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Email
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    {/*J: need to leave this for now so the other box is in the middle */}
                    <h4 className={styles.headerRightText} style={{flex: 1}}> </h4>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
